import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CompositionAPI from '@vue/composition-api'

import '@/assets/css/iconfont.less'
import '@/assets/css/common.less'

import './import-vant'
import { addEruda } from './shared/common'
import { getURLQuery, parseUrlParams, Storage } from './shared/utils'
import { StorageKey } from './constant/keys'

const initRiversAppId = parseUrlParams(location.href, ['riversAppId']).riversAppId
initRiversAppId && Storage.setKeyNS(`zy-${initRiversAppId || ''}`)

Vue.config.productionTip = false

Vue.use(CompositionAPI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

Storage.set(StorageKey.INITIAL_URL, location.href.split('#')[0])

try {
  /* 测试环境调试 */
  if (__APP_ENV__ === 'test') {
    addEruda()
  }
  // addEruda()
  if (getURLQuery(location.href).isdebug) {
    addEruda()
  }
} catch (e) {
  // do nothing
}