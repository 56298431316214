



import { defineComponent } from '@vue/composition-api'
import AuthLogin from './AuthLogin.vue'

export default defineComponent({
  components: {
    AuthLogin,
  },
})
