




















import { defineComponent } from '@vue/composition-api'

function getTabBars(riversAppId: string) {
  return [
    { name: '内容', to: 'content', 'icon': 'tabbar-content' },
    { name: '线索', to: 'clues', 'icon': 'tabbar-clues' },
    { name: '用户', to: 'customer', 'icon': 'tabbar-customer' },
    { name: '我的', to: 'profile', 'icon': 'tabbar-profile' },
  ].map(bar => {
    Object.assign(bar, {
      to: `/${riversAppId}/${bar.to}`,
    })
    console.log(bar.to)
    
    return bar
  })
}

export default defineComponent({
  data() {
    return {
      active: 0,

      TabBars: getTabBars(this.$route.params.riversAppId)
    }
  },
  methods: {
    isSelected(tab: { to: string }) {
      return this.$route.path.indexOf(tab.to) !== -1
    }
  }
})

