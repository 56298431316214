/* eslint-disable @typescript-eslint/no-explicit-any */
import { isString } from './utils'

/* 待类型优化 */
export const formatDate = (timestamp: any = new Date(), options?: any, isModify?: boolean) => {
  if (isString(timestamp) && timestamp.indexOf('+') === -1) {
    // Safari 只支持 2008/08/08 格式
    timestamp = timestamp.replace(/-/g, '/')
  }
  options = options || {};
  const temp = options.temp;
  const date = new Date(timestamp),
    formateds: any = {};
  formateds['YY'] = date.getFullYear();
  formateds['MM'] = date.getMonth() + 1;
  formateds['DD'] = date.getDate();
  formateds['hh'] = date.getHours();
  formateds['mm'] = date.getMinutes();
  formateds['ss'] = date.getSeconds();

  for (const key in formateds) {
    const val = formateds[key]
    formateds[key] = val >= 10 ? val : '0' + val;
  }

  if (temp) {
    let formatedText = temp;
    for (const key in formateds) {
      const val = formateds[key]
      formatedText = formatedText.replace(key, val);
    }
    return formatedText;
  }
  if(isModify){
    return {
      M:formateds.MM + '月',
      D:formateds.DD 
    }
  }
  return formateds.YY + '-' +
    formateds.MM + '-' +
    formateds.DD + ' ' +
    formateds.hh + ':' +
    formateds.mm + ':' +
    formateds.ss;
};
