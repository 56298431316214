export enum NotFoundType {
  AUTH_ERROR = 1,
  CONFIG_ERROR,
  UN_KNOWN_ERROR,
  NOT_FOUND_DETAIL_PAGE,
  NOT_IN_QY_WECHAT
}

export const NotFoundTypeNames = {
  [NotFoundType.AUTH_ERROR]: '权限错误',
  [NotFoundType.CONFIG_ERROR]: '配置错误, 请联系管理员',
  [NotFoundType.UN_KNOWN_ERROR]: '404 访问错误',
  [NotFoundType.NOT_FOUND_DETAIL_PAGE]: '未找到内容或内容已过期',
  [NotFoundType.NOT_IN_QY_WECHAT]: '请使用企业微信打开页面',
}