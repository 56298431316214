import { StorageKey } from '@/constant/keys'
import { Storage } from '@/shared/utils'
import { api, clearToken, getToken, setToken } from './api'

export type T_SendCodeParams = {
  number: string // 手机号
  areaCode: string // 区号 +86
}

export function saveLoginToken(params: { token: string }) {
  setToken(params.token)
}

export function logout() {
  clearToken()
}

export function checkIsLogin() {
  return !!getToken()
}

export type T_CorpDetail = {
  corpName: string
  type: WeChatType
  appId: string // 企业微信/微信的 id
  corpLogo?: string
}

/* 设置本地企业信息 */
export function setLocalCorpDetail(corpDetail: T_CorpDetail) {
  Storage.set(StorageKey.CORP_INFO, corpDetail)
}

/* 获取本地企业信息 */
export function getLocalCorpDetail() {
  const corpInfo = Storage.get(StorageKey.CORP_INFO) as T_CorpDetail | null

  if (corpInfo && corpInfo.corpName) {
    Object.assign(corpInfo, {
      corpName: corpInfo.corpName.replace('易有料北京', '工深之星'),
    })
  }

  return corpInfo
}

/* 发送手机验证码 */
export function sendPhoneCode(riversAppId: string, params: T_SendCodeParams): Promise<string> {
  // return Promise.resolve('9999')
  return api.get('/fishes/v2/h5/login/request_phone_code', {
    params: {
      phone: params.number,
    },
    headers: {
      appId: riversAppId,
    },
  })
}

export type T_VerifyCodeRes = {
  token: string
  expiresDate: string // 2021-10-29 09:12:33
}

/* 校验手机验证码 */
export function verifyPhoneCode(riversAppId: string, params: T_SendCodeParams & { verifyCode: string }): Promise<T_VerifyCodeRes> {
  // 临时 token
  // return axios.get('http://test.api.yiyouliao.com/fishes/v2/seller/log_temp?appId=1419587086566039553&userId=1419571594044383233').then(res => {
  //   return {
  //     token: res.data.requestId,
  //     expiresDate: new Date().toString()
  //   }
  // })
  return api.post(
    '/fishes/v2/h5/login/verify_phone_code',
    {
      phone: params.number,
      verifyCode: params.verifyCode,
    },
    {
      headers: { appId: riversAppId },
    }
  )
}

export enum WeChatType {
  WE_CHAT = 'DEFAULT', // 微信
  QY_WE_CHAT = 'CORP', // 企业微信
}

export type T_WeChatAppIdRes = {
  id: string
  corpName: string
  logoUrl: string
  agentId: string
}

/* 获取微信/企业微信的 corpId (用来鉴权登录) */
export function getWeChatAppId(appId: string, type: WeChatType): Promise<T_WeChatAppIdRes> {
  return api.get(`/fishes/v2/app/${appId}/weichat_info/${type}`)
}

/* 获取登录 token (根据企微授权后获取的 code) */
export function getTokenByWeChatCode(appId: string, type: WeChatType, code: string): Promise<T_VerifyCodeRes> {
  return api.get(`/fishes/v2/app/${appId}/weichat/${type}/${code}/token`)
}

export function getTokenByWeChatCodeV2(appId: string, type: WeChatType, code: string): Promise<T_VerifyCodeRes> {
  return api.get(`/fishes/v2/app/v2/${appId}/weichat/${type}/${code}/token`)
}

export function getWeChatTicketId(appId: string, type: WeChatType): Promise<{ jsapiTicket: string }> {
  return api.get(`/fishes/v2/app/${appId}/jsapi_ticket/${type}`)
}

export function updateWeChatUserInfo(code: string) {
  return api.put(`/fishes/v2/app/weichat/${code}`)
}
