import { ContentType } from '@/api/content'
import { checkIsLogin } from '@/api/login'
import { getSellerInfo, saveSellerCardInfo, SellerCardVisible, T_SellerBaseInfo, T_SellerCardInfo } from '@/api/profile'
import { copy } from '@/shared/utils'
import { getCurrentInstance, reactive, ref } from '@vue/composition-api'
import { Toast } from 'vant'

let sellerInfoCache: T_SellerBaseInfo | null = null
let sellerCardInfoCache: T_SellerCardInfo | null = null

export function useBaseInfo() {
  const ctx = getCurrentInstance()

  const route = ctx?.proxy.$route
  const riversAppId = route?.params.riversAppId || ''

  function toCustomerDetailRoute(customerId: string) {
    ctx?.proxy.$router.push(`/${riversAppId}/customer/${customerId}`)
  }

  return {
    riversAppId,
    toCustomerDetailRoute
  }
}

export function getEmptySellerInfo(): T_SellerBaseInfo {
  return copy({
    id: '',
    name: '',
    avatar: '',
    nickName: '',
    address: '',
    phone: '',
    gender: '',
    userRole: 'SELLER',
    createDate: '',
    updateDate: '',
    companyName: '',
    newsfeedId: ''
  })
}

export function getEmptySellerCardInfo() {
  return copy({
    id: '',
    userName: '',
    companyName: '',
    position: '',
    workingTime: '',
    headPic: '',
    phone: '',
    weiChatQrCode: '',
    corpWeiChatQrCode: '',
    cardSwitch: SellerCardVisible.OPEN,
    recommendSwitch: SellerCardVisible.OPEN
  })
}

export function useProfile() {
  const ctx = getCurrentInstance()
  
  const isProfileLoaded = ref(false)
  const { riversAppId } = useBaseInfo()
  const isLogined = checkIsLogin()

  const sellerInfo = reactive<T_SellerBaseInfo>(getEmptySellerInfo())
  const sellerCardInfo = reactive<T_SellerCardInfo>(getEmptySellerCardInfo())

  /* 走缓存 */
  if (sellerInfoCache && sellerCardInfoCache) {
    Object.assign(sellerInfo, sellerInfoCache)
    Object.assign(sellerCardInfo, sellerCardInfoCache)
    isProfileLoaded.value = true
  } else if (isLogined) {
    /* 从服务获取并赋值缓存 */
    getSellerInfo().then(info => {
      if (info && info.baseInfo) {
        Object.assign(sellerInfo, info.baseInfo)
        Object.assign(sellerCardInfo, info.businessCard)
        sellerInfoCache = info.baseInfo
        sellerCardInfoCache = info.businessCard
        isProfileLoaded.value = true
      }
    })
  } else {
    isProfileLoaded.value = true
  }

  function updateSellerCardInfo(updateOption?: { isShowToast: boolean }) {
    updateOption = updateOption || {
      isShowToast: true
    }
    saveSellerCardInfo(sellerCardInfo).then(() => {
      sellerCardInfoCache = sellerCardInfo
      updateOption?.isShowToast && Toast.success('保存成功')
    }).catch(err => {
      updateOption?.isShowToast && Toast.fail(err.message)
    })
  }

  function toContentDetailRoute(contentId: string, type: ContentType, contentPoolId = '') {
    if (!sellerInfo.id) {
      return ctx?.proxy.$notify('正在加载中, 请稍后')
    }
    // TODO 应必传 contentPoolId
    if (contentPoolId) {
      ctx?.proxy.$router.push(`/${riversAppId}/content/${contentPoolId}/${contentId}/${type}/${sellerInfo.id}`)
    } else {
      ctx?.proxy.$router.push(`/${riversAppId}/content/${contentId}/${type}/${sellerInfo.id}`)
    }
  }

  function toProfileRoute() {
    ctx?.proxy.$router.push(`/${riversAppId}/profile-card`)
  }

  return {
    sellerInfo,
    sellerCardInfo,
    toContentDetailRoute,
    isProfileLoaded,
    updateSellerCardInfo,
    toProfileRoute,
  }
}