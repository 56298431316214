












import { defineComponent } from '@vue/composition-api'
import '@/api/api'
import { EventEmitter, isInQYWeChatBrowser, isInWeChatBrowser } from './shared/utils'
import { getWXInstance, initWXInstance, openEnterpriseChat } from './shared/wx'
import { checkIsLogin } from './api/login'

export default defineComponent({
  provide() {
    return {
      reloadRoutePage: this.reload,
    }
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mounted() {
    // const isInWeChat = isInWeChatBrowser(),
    //   isInQYWeChat = isInQYWeChatBrowser()

    // if (!isInWeChat && !isInQYWeChat) {
    //   TODO 必须在微信 or 企业微信登录
    // }

    EventEmitter.on('memberNotFound', err => {
      this.$dialog.alert({ message: err.message })
      this.$router.push(`/404/desc/${err.message}`)
    })
    EventEmitter.on('memberNotLoginQY', err => {
      this.$dialog.alert({ message: '您还未登录易客星球，请先登录！' })
      this.$router.push(`/404/desc/${err.message}`)
    })
  },
  watch: {
    $route() {
      const riversAppId = this.$route.params.riversAppId
      riversAppId && initWeChatSDK(riversAppId)
    },
  },
  methods: {
    test() {
      // createChatWithMsg([])
      openEnterpriseChat([], ['wmGLu5DAAACHT007AQpggescuEfAa64g', 'wmGLu5DAAAhWUDqlQ9UonIZLf25VOEYg'])
    },
    reload() {
      this.isRouterAlive = false
      setTimeout(() => {
        this.isRouterAlive = true
      })
    },
  },
})

function initWeChatSDK(riversAppId: string) {
  // 已经初始化
  if (getWXInstance()) {
    return
  }

  // 未登录, 不请求
  if (!checkIsLogin()) {
    return
  }

  const isInWeChat = isInWeChatBrowser(),
    isInQYWeChat = isInQYWeChatBrowser()

  if (!isInWeChat && !isInQYWeChat) {
    return
  }

  if (!riversAppId) {
    return
  }
  
  initWXInstance(riversAppId)
}
