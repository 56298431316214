








import { updateWeChatUserInfo } from '@/api/login'
import { ylLog } from '@/shared/log'
import { getURLQuery } from '@/shared/utils'
import { defineComponent } from '@vue/composition-api'

/**
 * 此页面在 用户只配置微信(未配置企微) 的情况下进入
 * 在手机登录后. Web 进行网页授权, 获取 code , 再调用 server 接口同步个人信息
*/
export default defineComponent({
  mounted() {
    ylLog('Loaded AuthLogin WX')

    const riversAppId = this.$route.params.riversAppId as string

    const query = getURLQuery(location.href)
    const code = query.code

    // 微信未返回 code, 
    if (!code) {
      this.$dialog.alert({
        message: '微信授权失败',
      })
      this.$router.push(`/${riversAppId}/login`)
      return
    }

    ylLog('发送 code 更新用户信息', code)

    // 给 server 发送 code, 同步信息
    updateWeChatUserInfo(code).then(() => {
      this.$router.push({ query: {}, path: `/${riversAppId}/` })
    }).catch(err => {
      this.$dialog.alert({ message: err.message })
      this.$router.push({ query: {}, path: `/${riversAppId}/` })
      // this.$router.push({ query: {}, path: `/${riversAppId}/` })
    })


    return {}
  },
})
