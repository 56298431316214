import { T_SellerClues } from '@/api/clues'
import { ContentType, ContentTypeNames } from '@/api/content'
import { SellerCardVisible } from '@/api/profile'
import { UserAction } from '@/constant/content'
import { formatDate } from './date'
import { formatSeconds, getReadTimeByWords } from './utils'

export function getContentTitle(content: { contentType: ContentType; title: string }) {
  let title = ''
  if (content.contentType === ContentType.VIDEO) {
    title = '观看视频'
  } else if (content.contentType === ContentType.ARTICLE) {
    title = '阅读文章'
  } else {
    title = '阅读早报'
  }
  return `${title}《${content.title}》`
}

export function getContentDesc(readContent: T_DescContent): string {
  const list: string[] = []
  switch (readContent.content.contentType) {
    case ContentType.ARTICLE || ContentType.MORNING_PAPER:
      list.push(...getArticleDescList(readContent, readContent.content.contentType))
      break
    case ContentType.VIDEO:
      list.push(...getVideoDescList(readContent))
      break
  }
  return list.join('，')
}

export type T_DescContent = {
  content: {
    words?: number
    duration: number
    contentType: ContentType
    readDuration?: number
  }
  readDuration?: number
}

function getArticleDescList(readContent: T_DescContent, contentType: ContentType) {
  const list: string[] = []
  let tp = ''
  if (contentType && contentType === ContentType.MORNING_PAPER) {
    tp = '早报'
  }
  if (contentType && contentType === ContentType.ARTICLE) {
    tp = '文章'
  }
  if (readContent.content.words) {
    list.push(`${tp}共计 ${readContent.content.words} 字`)
    list.push(`预计阅读 ${getReadTimeByWords(readContent.content.words) || 0}`)
  }
  const readDuration = readContent.readDuration || readContent.content.readDuration
  if (readDuration) {
    list.push(`用户阅读 ${formatSeconds(readDuration)}`)
  }

  return list
}

function getVideoDescList(readContent: T_DescContent) {
  const list: string[] = []
  if (readContent.content.duration) {
    list.push(`视频时长 ${formatSeconds(readContent.content.duration)}`)
  }
  const readDuration = readContent.readDuration || readContent.content.readDuration
  if (readDuration) {
    list.push(`用户观看时长 ${formatSeconds(readDuration)}`)
  }
  return list
}

export function addEruda() {
  const script = document.createElement('script')
  script.src = '/js/eruda.js'
  script.onload = () => {
    const wd = window as any
    wd.eruda.init()
  }
  document.body.appendChild(script)
}

export function isSellerCardVisible(switchInfo: SellerCardVisible) {
  return switchInfo === SellerCardVisible.OPEN
}

export function getCluesDesc(clues: T_SellerClues) {
  const desc = {
    authorName: clues.baseInfo.name || clues.baseInfo.nickName || '测试用户',
    contentTitle: clues.baseContent.contentTitle,
    contentAction: '', // 阅读图文、观看视频、通过视频
    contentActionDetail: '', // 文章共计 xxx / 视频时长 xxx
    userAction: '', // 查看了
    actionName: '', // 您的名片, 您的电话
    actionType: clues.actionEnum, // 使用者再根据 actionType 区分操作
    time: formatDate(clues.actionTime),
  }
  const contentType = clues.baseContent.contentEnumType || ContentType.ARTICLE
  switch (clues.actionEnum) {
    case UserAction.PAGE_IN:
    case UserAction.PAGE_OUT:
      return Object.assign(desc, getReadCluesDesc(clues))
    case UserAction.LIKE:
      return Object.assign(desc, {
        contentAction: `点赞${ContentTypeNames[contentType]}`,
      })
    case UserAction.UNLIKE:
      return Object.assign(desc, {
        contentAction: `取消点赞${ContentTypeNames[contentType]}`,
      })
    case UserAction.SHARE:
      return Object.assign(desc, { contentAction: `转发${ContentTypeNames[clues.baseContent.contentEnumType]}` })
    case UserAction.VIEW_NAME_CARD:
      return Object.assign(desc, {
        contentAction: `通过${ContentTypeNames[contentType]}`,
        userAction: '查看了',
        actionName: '您的名片',
      })
    case UserAction.VIEW_PHONE:
      return Object.assign(desc, {
        contentAction: `通过${ContentTypeNames[contentType]}`,
        userAction: '查看了',
        actionName: '您的电话',
      })
    default:
      return desc
  }
}

function getReadCluesDesc(clues: T_SellerClues) {
  const contentType = clues.baseContent.contentEnumType
  const contentLength = clues.baseContent.targetLength
  const browseDuration = clues.baseContent.browseDuration
  switch (contentType) {
    case ContentType.VIDEO:
      return {
        contentAction: '观看视频',
        contentActionDetail: [
          contentLength && `视频时长${formatSeconds(contentLength)}`,
          browseDuration && `用户观看时长${formatSeconds(browseDuration / 1000)}`,
        ]
          .filter(v => v)
          .join(', '),
      }
    case ContentType.MORNING_PAPER:
      return {
        contentAction: '阅读早报',
        contentActionDetail: [
          contentLength && `早报共计${contentLength}个字`,
          contentLength && `预计阅读${getReadTimeByWords(contentLength)}`,
          browseDuration && `用户阅读时长${formatSeconds(browseDuration / 1000)}`,
        ]
          .filter(v => v)
          .join(', '),
      }
    default:
      return {
        contentAction: '阅读图文',
        contentActionDetail: [
          contentLength && `文章共计${contentLength}个字`,
          contentLength && `预计阅读${getReadTimeByWords(contentLength)}`,
          browseDuration && `用户阅读时长${formatSeconds(browseDuration / 1000)}`,
        ]
          .filter(v => v)
          .join(', '),
      }
  }
}

export function getContentFirstCover(covers: { url: string }[]) {
  return covers.length ? covers[0]?.url : ''
}
