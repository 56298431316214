









import { defineComponent } from '@vue/composition-api'
import { NotFoundType, NotFoundTypeNames } from '@/constant/404'

export default defineComponent({
  props: {
    type: {
      default: NotFoundType.UN_KNOWN_ERROR,
      type: Number
    }
  },
  data() {
    const notFoundType = (parseInt(this.$route.params.type) || this.type) as NotFoundType
    const notFoundDesc = this.$route.params.desc
    return {
      description: notFoundDesc || NotFoundTypeNames[notFoundType || NotFoundType.UN_KNOWN_ERROR]
    }
  }
})
