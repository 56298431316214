/*
  配置说明:
    BaseURL: 展业 H5 服务请求地址
    ImageProxyURL: 图片代理地址. 可避免图片防盗链检测. 使用: ImageProxyURL?url=https://图片地址
*/

export const API_CONFIG = {
  test: {
    BaseURL: 'http://tx-testfishes.yiyouliao.com',
    ImageProxyURL: 'http://tx-test.rivers.yiyouliao.com/image_refer',
    RiversAPIBaseURL: 'http://tx-test.api.yiyouliao.com',
    DataReportBaseURL: 'http://tx-test.analytics.yiyouliao.com', // 数据上报
  },
  prod: {
    BaseURL: 'https://api.youkewang.site',
    ImageProxyURL: 'https://rivers.yiyouliao.com/image_refer',
    RiversAPIBaseURL: 'https://api.yiyouliao.com',
    DataReportBaseURL: 'https://analytics.yiyouliao.com',
  },
}[__APP_ENV__ || 'prod']
