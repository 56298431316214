import { StorageKey } from '@/constant/keys'
import { T_PageParams, T_PageRes } from '@/hook/useList'
import { Storage } from '@/shared/utils'
import { AxiosRequestHeaders } from 'axios'
import { api } from './api'
import { SellerCardVisible } from './profile'

export enum ContentType {
  ARTICLE = 'ARTICLE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  POSTER = 'POSTER',
  MORNING_PAPER = 'MORNING_PAPER',
}

export const ContentTypeNames = {
  [ContentType.ARTICLE]: '图文',
  [ContentType.VIDEO]: '视频',
  [ContentType.AUDIO]: '音频',
  [ContentType.POSTER]: '海报',
  [ContentType.MORNING_PAPER]: '早报',
}

export type T_ContentListParams = T_PageParams & {
  contentType: ContentType
  categoryId?: string
}

export type T_Content = {
  contentId: string
  contentType: ContentType
  title: string
  duration: number
  words: number
  categoryId: string
  author: {
    authorName: string
    author_name?: string
    avatar: string
    id: string
  }
  browseNumber: number
  shareNumber: number
  createContentTime: string
  publishContentTime: string
  publishTime: string
  tags: string[]
  uri: string
  coverPics: { url: string }[]

  // new
  sourceContentId: string
  privateContentPoolId: string
  contentStatus: string // 'UP'
  category: {
    categoryId: string
    categoryName: string
  }
  summary: string
  originUrl: string
  coverType: string // SINGLE_IMAGE
  landingPageUrl: string
  createTime: string
  createBy: string
  updateTime: string
  updateBy: string
}

export type T_GetPollContentParams = T_PageParams & {
  contentPollId: string
  signal: any
}

export function getContentListByPollId(params: T_GetPollContentParams, options: any): Promise<T_PageRes<T_Content[]>> {
  return api.get(`/fishes/v2/v2/content/${params.contentPollId}/list`, { params, ...options })
}

export type T_GetKeywordContentParams = T_PageParams & {
  keyword: string
}

export function getContentListByKeyword(params: T_GetKeywordContentParams): Promise<T_PageRes<T_Content[]>> {
  return api.get('/fishes/v2/rivers/web/contents', { params })
}

export function getNewContentList(params: T_GetKeywordContentParams & T_GetPollContentParams, options?: any) {
  return params.keyword ? getContentListByKeyword(params) : getContentListByPollId(params, options)
  // return getContentListByKeyword(params)
}

/* 获取内容列表 */
export function getContentList(params: T_ContentListParams): Promise<T_PageRes<T_Content[]>> {
  return api.get('/fishes/v2/content', { params })
}

export type T_ContentBaseDetail = {
  contentId: string
  contentType: ContentType
  landingPageUrl: string
  title: string
  summary: string
  tags: string[]
  version: number
  author: {
    authorName: string
    avatar: string
    authorOriginType: string
    createTime: string
    updateTime: string
    id: string
  }
  publishTime: string
  updateTime: string
  createTime: string
  deliveryTime: string
  categoryId: string
  category: {
    id: string
    name: string
    categoryName?: string
  }
  content: string
  video: {
    url: string
    duration: number
  }
  contentStatus: 'UP' | 'DOWN'
  contentVideo?: {
    contentId: string
    duration: number
    height: number
    width: number
    size: number
    url: string
    version: number
  }
  words: number
  zanNumber: number
  shareNumber: number
  browseNumber: number
  coverPics: {
    url: string
    height: number
    width: number
    imageType: string
  }[]
  privateContentPoolId: string
  sourceContentId: string // 消息源内容id
  morningPaperType?: string
  morningPaperPreviewPic?: string
}

export function getContentDetail(
  contentId: string,
  contentPoolId: string,
  sellerId: string,
  headers?: AxiosRequestHeaders
): Promise<T_ContentBaseDetail> {
  // return mockReq({
  //   contentId: '22222',
  //   contentType: ContentType.ARTICLE,
  //   landingPageUrl: 'https://h5detail.yiyouliao.com/rivers/newsfeed/d5524bebf7094e259f6a9f37211a84d3/1437270085893914625.html?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhcHBJZCI6IjE0MjM1NjEyNDI4NDg1NjMyMDIiLCJleHAiOjE2Mzc4OTk3NTgsInVzZXJJZCI6ImFkbWluVXNlciJ9.TrzpE53mRxL8YTjgAw7-a77fc0UZFuMDQIFKsnx88Ks',
  // })
  return api.get(`/fishes/v2/v2/content/${contentId}/details`, {
    params: { sellerId, contentPoolId },
    headers,
  })
}

export type T_ContentReviewRes = {
  forwards: string[]
}

export type T_ContentSwitchVisible = {
  id: string
  appId: string
  sellerId: string
  contentId: string
  cardSwitch: SellerCardVisible
  recommendSwitch: SellerCardVisible
  contactMeSwitch: SellerCardVisible
}
export type T_SwitchVisibleParams = {
  sellerId: string
  contentId: string
}

export type T_SetSwitchVisibleParams = {
  sellerId: string
  contentId: string
  cardSwitch: SellerCardVisible
  recommendSwitch: SellerCardVisible
  contactMeSwitch: SellerCardVisible
}

export function getContentReviews(contentId: string): Promise<T_ContentReviewRes> {
  // return mockReq({
  //   summary: '',
  //   reviews: [
  //     '如果你是没有经验的小白，资产配置建议以稳',
  //     '如果手上之忧一两万，一般都还不会想到去规',
  //     '如果你手上有10万元闲钱，会怎么做呢？'
  //   ]
  // })
  return api.get(`/fishes/v2/content/${contentId}/forwards`)
}

export function getContentReviewsByText(contentId: string, isRebuild?: boolean): Promise<T_ContentReviewRes> {
  const params = {
    isRebuild: isRebuild || false,
  }
  return api.post(`/fishes/v2/content/${contentId}/forwards`, params)
}

export function getContentSwitchVisible(params: T_SwitchVisibleParams, headers?: AxiosRequestHeaders): Promise<T_ContentSwitchVisible> {
  return api.get(`/fishes/v2/switch`, {
    params,
    headers,
  })
}

export function setContentSwitchVisible(params: T_SetSwitchVisibleParams) {
  // 不要直接传 params, 如果多传 appId 等参数, 服务会解析错误
  return api.post(`/fishes/v2/switch`, {
    sellerId: params.sellerId,
    contentId: params.contentId,
    cardSwitch: params.cardSwitch,
    recommendSwitch: params.recommendSwitch,
    contactMeSwitch: params.contactMeSwitch,
  })
}

export type T_RecommendParams = {
  sellerId: string
  categoryId: string
  contentId: string
  customerId: string
} & T_PageParams

/* 推荐内容列表 */
export function getContentRecommendList(params: T_RecommendParams): Promise<T_PageRes<T_Content[]>> {
  const headers: Record<string, string> = {}
  const userToken = Storage.get(StorageKey.USER_TOKEN) as string
  if (userToken) {
    headers.token = userToken
  }
  return api.get(`/fishes/v2/content/more_recommendations`, {
    params,
    headers,
  })
}

export type T_ContentMenu = {
  order: number
  id: string
  name: string
  typeEnum?: string // 暂不定义枚举, 直传服务
  childMenuList?: T_ContentMenu[]
}

export function getContentMenus(): Promise<T_ContentMenu[]> {
  return api.get('/fishes/v2/rivers/web/menu')
}

// 获取内容搜索的历史关键词、推荐关键词
export function getContentSearchKeywords(): Promise<{ historyKeywords: string[]; recommendKeywords: string[] }> {
  // return mockReq({
  //   historyKeywords: ['文艺青年', '跟进青年', '大龄青年', '大龄青年', '大龄青年', '大龄青年', '大龄青年'],
  //   recommendKeywords: ['文艺青年', '跟进青年', '大龄青年'],
  // })
  return api.get('/fishes/v2/rivers/web/keyword')
}

export function setContentSearchKeyword(keyword: string) {
  return api.post('/fishes/v2/rivers/web/keyword', {
    keyword,
  })
}

export function getLikeStatus(userId: string, contentId: string): Promise<boolean> {
  return api.get(`/fishes/v2/${userId}/${contentId}/like/state`)
}

export function getRecommendContentList(userId: string, contentId?: string, params?: T_PageParams): Promise<T_PageRes<T_Content[]>> {
  return api.get(`/fishes/v2/${userId}/contents`, {
    params: {
      ...params,
      contentId,
    },
  })
}
