// import { mockReq } from '@/mock/mockReq'
import { AxiosRequestHeaders } from 'axios'
import { api } from './api'
import { ContentType } from './content'
import { T_UserBaseInfo } from './customer'

export type T_SellerInfo = {
  baseInfo: T_UserBaseInfo,
  character: {
    id: string,
    name: string,
    industry: {
      id: string,
      name: string
    },
    contentsConfigInfo: {
      id: string,
      ruleName: string,
      updateFrequencyType: string, // WEEK
      updateFrequencyCount: number,
      baseCount: number,
      creator: string, // 名字
      configs: {
        id: string,
        category: {
          id: string,
          name: string
        },
        contentConfigs: {
          contentType: ContentType,
          baseCount: number,
          updateCount: number
        }[]
      }[]
    }
  }
}

export type T_SellerInfoDetails = {
  baseInfo: T_SellerBaseInfo,
  businessCard: T_SellerCardInfo
}

export type T_SellerBaseInfo = {
  id: string,
  name: string,
  avatar: string,
  nickName: string,
  companyName: string,
  address?: string,
  phone: string,
  gender: string,
  userRole?: 'SELLER',
  createDate?: string,
  updateDate?: string,
  newsfeedId: string
}

export function getSellerInfo(): Promise<T_SellerInfoDetails> {
  return api.get('/fishes/v2/seller/');
}

export enum SellerCardVisible {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export type T_SellerCardInfo = {
  id: string,
  userName: string,
  companyName: string,
  position: string, // 职位
  workingTime: string, // 工作年限
  headPic: string,
  phone: string,
  weiChatQrCode: string,
  corpWeiChatQrCode: string,
  cardSwitch: SellerCardVisible,
  recommendSwitch: SellerCardVisible
}

export function saveSellerCardInfo(sellerCardInfo: T_SellerCardInfo) {
  return api.put('/fishes/v2/seller/business_card_info', {
    userName: sellerCardInfo.userName,
    companyName: sellerCardInfo.companyName,
    position: sellerCardInfo.position,
    workingTime: sellerCardInfo.workingTime,
    headPic: sellerCardInfo.headPic,
    phone: sellerCardInfo.phone,
    weiChatQrCode: sellerCardInfo.weiChatQrCode,
    cardSwitch: sellerCardInfo.cardSwitch,
    recommendSwitch: sellerCardInfo.recommendSwitch,
  })
}

export function getCustomerUserId(headers?: AxiosRequestHeaders): Promise<{ id: string, name: string }> {
  return api.get('/fishes/v2/customer', {
    headers
  })
}

export enum HistoricalContentType {
  ARTICLE = 'ARTICLE',
  MORNING_PAPER = 'MORNING_PAPER',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  POSTER = 'POSTER',
  IMAGE = 'IMAGE',
  ALL = 'ALL'
}

export type T_HistoricalRecordsParams = {
  pageNumber: number,
  pageSize: number,
  contentType: string
}
type T_Page = {
  ageNumber: number,
  pageSize: number,
  total: number,
}
export type T_HistoricalRecord = {
  browseNumber: number,
  contentId: string,
  contentTitle: string,
  title?: string,
  contentUrl: string,
  latelyBrowseTime: string,
  shareNumber: number,
  zanNumber: number,
  contentPoolId?: string,
  privateContentPoolId: string
}
export type T_HistoricalRecordsRes = {
  page: T_Page,
  records: T_HistoricalRecord[]
}

export type T_Tab = {
  name: string,
  contentType: string
}

export function getHistoricalSharingRecords(params: T_HistoricalRecordsParams): Promise<T_HistoricalRecordsRes> {
  return api.get(`/fishes/v2/history/shard/contents`, {
    params
  })
}

export enum QueryType {
  TIME = 'TIME',
  BROWSE = 'BROWSE',
  DURATION = 'DURATION'
}

export enum OrderArrow {
  ASC = 'ASC',
  DESC = 'DESC',
  NO = 'NO'
}

export type T_StatisticsParams = {
  pageNumber: number,
  pageSize: number,
  queryType: QueryType,
  order: OrderArrow
}
export type T_ShareInfo = {
  avatar: string,
  name: string,
  id: string,
  nickName?: string
}
export type T_BrowsingHistoryRecord = {
  baseInfo: {
    avatar: string,
    name: string,
    id: string,
    lastReadTime: string,
    source?: string,
    externalUserId: string,
    sellerCustomer: boolean,
    nickName?: string
  },
  browseNumber: number,
  shareInfos: T_ShareInfo[],
  shareId: string
}
export type T_StatisticsRes = {
  checkNumber: number,
  checkPeopleCount: number,
  shareNumber: number,
  shareModelPageResp: { // 分享链路表
    page: {
      pageNumber: number,
      pageSize: number,
      total: number
    },
    records: T_BrowsingHistoryRecord[]
  },
  contentRecord: {
    author: {
      authorId: string,
      authorName: string,
      avatar: string
    },
    contentId: string,
    contentType: ContentType,
    privateContentPoolId: string,
    title: string
  }
}
export function getForwardingStatistics(contentId: string, contentPoolId: string, params: T_StatisticsParams): Promise<T_StatisticsRes> {
  // return mockReq({
  //   "checkNumber": 23322,
  //   "shareModelPageResp": {
  //     "records": [
  //       {
  //         "baseInfo": {
  //           "id": "4",
  //           "name": "历商圆",
  //           "phone": "13344055824",
  //           "companyName": "大使标广",
  //           "updateDate": "1947-03-07T23:37:48.213Z",
  //           "createDate": "2004-11-05T18:52:41.839Z",
  //           "newsfeedId": "73",
  //           "userRole": "SELLER",
  //           "address": "甘肃省遂宁市静安区",
  //           "lastReadTime": "2022-01-22 18:00",
  //           "gender": "WOMAN",
  //           "nickName": "杨杰",
  //           "corpReferred": "reprehenderit culpa",
  //           "avatar": "http://dummyimage.com/100x100",
  //           "level": "VISIT",
  //           "sellerCustomer":false,
  //           "externalUserId":''
  //         },
  //         "browseNumber": 29,
  //         "shareInfos": [
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商圆",
  //             "id": "4",
  //           },
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商啊啊啊圆",
  //             "id": "5",
  //           }
  //         ],
  //         "shareId":'1'
  //       },
  //       {
  //         "browseNumber": 43,
  //         "shareId":'12',

  //         "shareInfos": [
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商圆",
  //             "id": "4",
  //           },
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商啊啊啊圆",
  //             "id": "5",
  //           },
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商啊啊啊圆",
  //             "id": "6",
  //           },
  //           {
  //             "avatar": "http://dummyimage.com/100x100",
  //             "name": "历商啊啊啊圆",
  //             "id": "7",
  //           },
  //         ],
  //         "baseInfo": {
  //           "id": "39",
  //           "userRole": "CUSTOMER",
  //           "newsfeedId": "66",
  //           "level": "IMPORTANCE",
  //           "companyName": "置还八",
  //           "avatar": "http://dummyimage.com/100x100",
  //           "nickName": "孟军",
  //           "address": "上海毕节市元宝区",
  //           "name": "验消只写开技",
  //           "corpReferred": "in ut velit minim occaecat",
  //           "updateDate": "1981-05-24T00:39:34.201Z",
  //           "phone": "18693167163",
  //           "gender": "WOMAN",
  //           "createDate": "1988-06-10T04:10:10.069Z",
  //           "lastReadTime": "2022-01-22 18:00",
  //           "sellerCustomer":false,
  //           "externalUserId":''
  //         }
  //       }
  //     ],
  //     "page": {
  //       "total": 2,
  //       "pageSize": 10,
  //       "pageNumber": 1
  //     }
  //   },
  //   "shareNumber": 23455,
  //   "checkPeopleCount": 8890,
  //   "contentRecord": {
  //     "sourceContentId": "89",
  //     "browseNum": 80865758,
  //     "author": {
  //       "authorName": "华备备华山包",
  //       "initCategoryId": "68",
  //       "authorId": "19",
  //       "avatar": "http://dummyimage.com/100x100",
  //       "introduction": "et esse"
  //     },
  //     "coverPics": [
  //       {
  //         "width": -58886059,
  //         "height": -34648814,
  //         "blurScore": 94409456.8368501,
  //         "imageType": "http://dummyimage.com/400x400",
  //         "format": "eiusmod aliqua anim occaecat cupidatat",
  //         "url": "http://fxylbb.ni/dvtyywbeo"
  //       },
  //       {
  //         "url": "http://bkcj.com/rtnexibvf",
  //         "width": -27600774,
  //         "imageType": "http://dummyimage.com/400x400",
  //         "blurScore": -41843456.635539725,
  //         "format": "ea nulla aliquip Lorem ex",
  //         "height": -22279936
  //       },
  //       {
  //         "url": "http://evjpwppxb.ml/hshum",
  //         "height": 44351746,
  //         "blurScore": 64131452.789862335,
  //         "width": -5372570,
  //         "format": "esse",
  //         "imageType": "http://dummyimage.com/400x400"
  //       },
  //       {
  //         "format": "sint nulla nostrud mollit ex",
  //         "width": -67215317,
  //         "imageType": "http://dummyimage.com/400x400",
  //         "blurScore": 58054571.86095962,
  //         "url": "http://qkevvfus.nt/oznipi",
  //         "height": 14410194
  //       }
  //     ],
  //     "category": {
  //       "id": "14",
  //       "name": "断天月断"
  //     },
  //     "contentId": "10",
  //     "createBy": "consequat deserunt",
  //     "shareNum": 27849205,
  //     "tags": [
  //       "ut aute labore",
  //       "ut incididunt occaecat"
  //     ],
  //     "words": -43065722,
  //     "originUrl": "http://bmfik.as/kiag",
  //     "updateBy": "officia pariatur proident qui",
  //     "privateContentPoolId": "95",
  //     "createTime": "1992-12-13T03:09:17.412Z",
  //     "content": "ullamco",
  //     "coverType": "BIG_IMAGE",
  //     "summary": "in proident commodo",
  //     "contentType": "IMAGE",
  //     "zanNum": 73871687,
  //     "video": {
  //       "height": -63576366,
  //       "size": 65958314,
  //       "width": 60825616,
  //       "type": "HD",
  //       "bitRate": 13451276,
  //       "frameRate": -53457819,
  //       "duration": -9984092,
  //       "url": "http://qomns.nu/ryicuqlsj"
  //     },
  //     "updateTime": "1977-12-24T23:07:59.164Z",
  //     "contentStatus": "UP",
  //     "title": "平各况规"
  //   }

  // })
  return api.get(`/fishes/v2/${contentId}/${contentPoolId}/share/statistics`, { params })
}