const Head = '易有料展业'

function formatArgs(...args: unknown[]) {
  const newArgs: unknown[] = []
  args.forEach((arg) => {
    newArgs.push(
      typeof arg === 'object'
        ? JSON.stringify(arg)
        : arg
    )
  })
  return newArgs.join(' ')
}

function normalLog(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log(...args)
}

export function ylLog(...args: unknown[]) {
  return normalLog(`%c[${Head}] ${formatArgs(args)}`, 'color: blue')
}

export function ylError(...args: unknown[]) {
  return normalLog(`%c[${Head}] ${formatArgs(args)}`, 'color: red')
}

export function ylWarn(...args: unknown[]) {
  return normalLog(`%c[${Head}] ${formatArgs(args)}`, 'color: orange')
}