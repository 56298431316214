import { API_CONFIG } from '@/config/api'
import { ERROR } from '@/constant/error'
import { EventEmitter, isObject, isUndefined, Storage } from '@/shared/utils'
import axios from 'axios'

const StoreTokenKey = 'token'
let token = Storage.get(StoreTokenKey) as string | null

export function setToken(t: string) {
  token = t
  Storage.set(StoreTokenKey, t)
}

export function getToken() {
  token = Storage.get(StoreTokenKey) as string | null
  return token || ''
}

export function clearToken() {
  Storage.remove(StoreTokenKey)
  token = ''
}

export const api = axios.create({
  baseURL: API_CONFIG.BaseURL,
})

api.interceptors.request.use(function (config) {
  config.headers = config.headers || {}
  if (!config.headers.token) {
    config.headers.token = token as string
  }

  return config
})

api.interceptors.response.use(function (response) {
  if (!response || !response.data || isUndefined(response.data.code)) {
    return Promise.reject({
      code: '-1',
      message: 'server error',
    })
  }

  if (response.data.code !== 0) {
    console.error('【Server Error】: ', response.data)

    /* 1003 为鉴权过期, 暂直接清空缓存, 刷新页面 */
    if (response.data.code === 1003) {
      Storage.clear()
      window.location.reload()
    }

    if (response.data.code === ERROR.NOT_FOUND_MEMBER) {
      Storage.clear()
      EventEmitter.emit('memberNotFound', response.data)
    }
    if (response.data.code === ERROR.NOT_LOGIN_QY) {
      // Storage.clear()
      // EventEmitter.emit('memberNotLoginQY', response.data)
    }
    return Promise.reject(response.data)
  }

  const data = response.data.data
  const page = response.data.page

  if (Array.isArray(data) && isObject(page)) {
    // 将 page 与 data 合并, 作为业务数据返回
    return { data, page }
  }

  return data
})
