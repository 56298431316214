import { ContentType, T_ContentBaseDetail } from '@/api/content'

export const EmptyContent: T_ContentBaseDetail = {
  contentId: '',
  contentType: ContentType.ARTICLE,
  landingPageUrl: '',
  title: '',
  summary: '',
  tags: [],
  version: 0,
  author: {
    authorName: '',
    avatar: '',
    authorOriginType: '',
    createTime: '',
    updateTime: '',
    id: '',
  },
  categoryId: '',
  category: {
    id: '',
    name: ''
  },
  content: '',
  publishTime: '',
  updateTime: '',
  createTime: '',
  deliveryTime: '',
  video: { url: '', duration: 0 },
  coverPics: [],
  contentStatus: 'UP',
  words: 0,
  zanNumber: 0,
  shareNumber: 0,
  browseNumber: 0,
  privateContentPoolId: '',
  sourceContentId: ''
}

export enum UserAction {
  PAGE_IN = 'PAGE_IN', // 进入页面
  PAGE_OUT = 'PAGE_OUT', // 出页面
  LIKE = 'LIKE', // 点赞
  UNLIKE = 'UNLIKE', // 取消点赞
  SHARE = 'SHARE', // 分享
  VIEW_PHONE = 'VIEW_PHONE', // 查看电话
  VIEW_NAME_CARD = 'VIEW_NAME_CARD' // 查看名片
}