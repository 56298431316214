






import { checkIsLogin, getTokenByWeChatCode, getTokenByWeChatCodeV2, saveLoginToken, WeChatType } from '@/api/login'
import { getURLQuery } from '@/shared/utils'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    isV2: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const riversAppId = this.$route.params.riversAppId as string

    if (checkIsLogin()) {
      // 已登录, 直接进入首页
      return this.$router.push(`/${riversAppId}`)
    }

    const query = getURLQuery(location.href)
    const code = query.code
    if (!code) {
      this.$dialog.alert({
        message: '企业微信授权失败',
      })
      return
    }

    const appId = this.$route.params.riversAppId || ''
    const api = this.isV2 ? getTokenByWeChatCodeV2 : getTokenByWeChatCode
    api(appId, WeChatType.QY_WE_CHAT, code)
      .then(res => {
        saveLoginToken(res)
        const query = {}
        if (this.$route.query.isdebug) {
          Object.assign(query, { isdebug: 'true' })
        }
        this.$router.push({
          path: `/${appId}/`,
          query: query,
        }) // 跳转至首页
      })
      .catch(err => {
        this.$dialog.alert({ message: err.message })
      })
  },
})
