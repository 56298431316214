/* eslint-disable @typescript-eslint/no-explicit-any */
import Cookies, { CookieAttributes } from 'js-cookie'

export function isObject(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

export function isUndefined(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === '[object Undefined]';
}
export function isString(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === '[object String]';
}

export function isNumber(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === '[object Number]';
}

export function isEmptyObj(obj: Record<string, any>) {
  return Object.keys(obj).length === 0
}

type T_StorageData = {
  type: string,
  data: unknown
}

export function copy(obj: Record<string, any> | any[]) {
  return JSON.parse(JSON.stringify(obj))
}

export function setClipboardData(text: string) {
  const inputEl = document.createElement('input')
  inputEl.style.opacity = '0'
  inputEl.style.position = 'absolute'
  inputEl.style.bottom = '0'
  inputEl.style.left = '0'
  inputEl.value = text
  document.body.appendChild(inputEl)
  inputEl.select()
  document.execCommand('copy')
  setTimeout(() => {
    document.body.removeChild(inputEl)
  })
}

export const Storage = (function () {
  let keyNS = 'zhanye-'

  function isKeyExist(key: string) {
    return Object.prototype.hasOwnProperty.call(localStorage, key)
      || Object.prototype.hasOwnProperty.call(sessionStorage, key)
  }

  function setKeyNS(ns: string) {
    keyNS = ns
  }

  function get(key: string) {
    const tempKey: string = keyNS + key;
    if (!isKeyExist(tempKey)) {
      return null;
    }
    const jsonVal = localStorage.getItem(tempKey) || sessionStorage.getItem(tempKey);
    let val: T_StorageData
    if (jsonVal) {
      val = JSON.parse(jsonVal) as T_StorageData
      if (val && val.type && val.data) {
        return val.data
      }
    }

    return null;
  }

  function set(key: string, val: unknown) {
    const store = localStorage || sessionStorage
    store.setItem(keyNS + key, JSON.stringify({
      data: val,
      type: (typeof val)
    }));
  }

  function remove(key: string) {
    const tempKey = keyNS + key;
    localStorage.removeItem(tempKey);
    sessionStorage.removeItem(tempKey);
  }

  function clear() {
    /* TODO 待优化, 应只清除业务相关 localStorage */
    localStorage.clear()
    sessionStorage.clear()
  }

  return {
    setKeyNS: setKeyNS,
    get: get,
    set: set,
    remove: remove,
    clear
  };
})();

export const Cookie = (function () {
  const keyNS = 'zhanye-'

  function getKey(key: string) {
    return keyNS + key
  }

  function set(name: string, value: string, options?: CookieAttributes) {
    return Cookies.set(getKey(name), value, options)
  }

  function get(name: string) {
    return Cookies.get(getKey(name))
  }

  function remove(name: string, options?: CookieAttributes) {
    return Cookies.remove(getKey(name), options)
  }

  return {
    set,
    get,
    remove
  }
})()

export function getDomain(level = 2) {
  const list = document.domain.split('.')
  if (list.length <= level) {
    return list.join('.')
  }
  return list.slice(list.length - level).join('.')
}

export function getURLQuery(url: string) {
  const deocdeUrl = decodeURIComponent(url)
  const urlInstance = new URL(deocdeUrl)
  const kv: Record<string, string> = {}

  urlInstance.searchParams.forEach((val, key) => {
    kv[key] = val
  })
  return kv
}

/* 分 转化为 时分秒 */
export function formatSeconds(value: string | number) {
  const result = parseInt(value + '')
  if (!result) {
    return '0 秒'
  }
  const h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
  const m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
  const s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))
  
  return [
    { name: '时', val: h },
    { name: '分', val: m },
    { name: '秒', val: s },
  ]
    .filter((v) => v.val !== '00')
    .map(({ name, val }) => {
      return `${val}${name}`
    }).join('')
}

/* 根据字数获取所需阅读时长 */
export function getReadTimeByWords(words: number) {
  const seconds = words / 275 * 60
  return formatSeconds(seconds)
}

export function setTitle(title: string) {
  document.title = title
}

export function isInWeChatBrowser() {
  return /micromessenger/i.test(navigator.userAgent)
}

export function isInQYWeChatBrowser() {
  return /wxwork/i.test(navigator.userAgent)
}

type T_EventFunc = (...args: any[]) => void

const Events: Record<string, T_EventFunc[]> = {}

export const EventEmitter = {
  on(eventName: string, eventFunc: T_EventFunc) {
    const eventList: T_EventFunc[] = Events[eventName] || []
    eventList.push(eventFunc)
    Events[eventName] = eventList
  },
  off(eventName: string, eventFunc: T_EventFunc) {
    const eventList: T_EventFunc[] = Events[eventName] || []
    const newEventList: T_EventFunc[] = []
    eventList.forEach(func => {
      if (func !== eventFunc) {
        newEventList.push(func)
      }
    })
    Events[eventName] = newEventList
  },
  emit(eventName: string, ...args: any[]) {
    const eventList = Events[eventName] || []
    eventList.forEach(event => {
      event && event(...args)
    })
  },
  emitLast(eventName: string, ...args: any[]) {
    const eventList = Events[eventName] || []
    eventList.length && eventList[eventList.length - 1](...args)
  },
  has(eventName: string): boolean {
    const eventList = Events[eventName] || []
    return eventList.length !== 0
  },
  clear(eventName: string) {
    delete Events[eventName]
  }
}

export function setFavicon(url: string) {
  const link: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link')
  link.type = 'image/x-icon'
  link.rel = 'shortcut icon'
  link.href = url

  document.getElementsByTagName('head')[0].appendChild(link);
}

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WINDOWS = 'WINDOWS',
  LINUX = 'LINUX',
  MAC = 'MAC',
  IPAD = 'IPAD',
  UNKNOWN = 'UNKNOWN'
}

/* 设备类型 */
export function getDeviceType(): DeviceType {
  const agent = navigator.userAgent.toLowerCase();
  if (/windows/.test(agent)) {
    return DeviceType.WINDOWS;
  } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
    return DeviceType.IOS;
  } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
    return DeviceType.IPAD;
  } else if (/android/.test(agent) && /mobile/.test(agent)) {
    return DeviceType.ANDROID;
  } else if (/linux/.test(agent)) {
    return DeviceType.LINUX;
  } else if (/mac/.test(agent)) {
    return DeviceType.MAC;
  } else {
    return DeviceType.UNKNOWN;
  }
}

export function parseUrlParams(url: string, paramKeys: string[]): Record<string, string> {
  const urlCtx = new URL(url)
  const { pathname } = urlCtx
  const params: Record<string, string> = {}
  pathname.split('/').slice(1).forEach((val, index) => {
    const key = paramKeys[index]
    if (key && val) {
      params[key] = val
    }
  })
  return params
}

/**
 * 计时器
 * start: 开始计时
 * stop: 结束计时
 * getTotalTime: 获取总计时时间
 */
export class Timer {
  private isTiming = true
  private finishedTime = 0
  private currentStartDate: Date = new Date()

  constructor() {
    // do nothing
  }
  start() {
    this.isTiming = true
    this.currentStartDate = new Date()
  }
  stop() {
    this.isTiming = false
    const endDate = new Date()
    const duration = endDate.getTime() - this.currentStartDate.getTime()
    this.finishedTime += duration
    return this.finishedTime
  }
  getTotalTime() {
    // 已结束时, 返回已经结束的计时时间
    if (!this.isTiming) {
      return this.finishedTime
    }
    const endDate = new Date()
    const duration = endDate.getTime() - this.currentStartDate.getTime()
    return this.finishedTime + duration
  }
}

export function htmlToText(html: string) {
  const div = document.createElement('div')
  div.innerHTML = html
  const text = div.innerText
  return text
}

export function imageUrlToBase64(url: string): Promise<string> {
  const img = new Image()
  img.crossOrigin = 'Anonymous'
  img.src = `${url}?t=${Date.now()}`

  return new Promise((resolve, reject) => {
    img.onload = function () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      ctx?.drawImage(img, 0, 0)
      const base64 = canvas.toDataURL('image/png')
      resolve(base64)
    }

    img.onerror = reject
  })
}

export function scrollTop() {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export function clearArr(arr:unknown[]) {
  arr.splice(0, arr.length)
}

// 处理色值
export function gradient(startColor: any, endColor: any, step: any) {
  function hexToRgb(hex: any) {
    const rgb = [];
    for (let i = 1; i < 7; i += 2) {
      rgb.push(parseInt("0x" + hex.slice(i, i + 2)));
    }
    return rgb;
  }
  
  function rgbToHex(r: any, g: any, b: any) {
    const hex = ((r << 16) | (g << 8) | b).toString(16);
    return "#" + new Array(Math.abs(hex.length - 7)).join("0") + hex;
  }
  //将hex转换为rgb
  const sColor = hexToRgb(startColor),
    eColor = hexToRgb(endColor);
    
  //计算R\G\B每一步的差值
  const rStep = (eColor[0] - sColor[0]) / step;
  const gStep = (eColor[1] - sColor[1]) / step;
  const bStep = (eColor[2] - sColor[2]) / step;
  const gradientColorArr = [];

  for (let i = 0; i < step; i++) {
    //计算每一步的hex值
    gradientColorArr.push(
      rgbToHex(
        parseInt(String(rStep * i + sColor[0])),
        parseInt(String(gStep * i + sColor[1])),
        parseInt(String(bStep * i + sColor[2]))
      )
    );
  }

  return gradientColorArr;
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}