import Vue from 'vue'

import {
  Button,
  Icon,
  Empty,
  Tabbar,
  TabbarItem,
  Loading,
  Dialog,
  Form,
  Field,
  Notify,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Divider,
  Tag,
  Search,
  DropdownMenu,
  DropdownItem,
  Image,
  Cell,
  CellGroup,
  Uploader,
  ActionSheet,
  Skeleton,
  Picker,
  Switch,
  Locale,
  Col, 
  Row,
  Radio,
  RadioGroup
} from 'vant'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const zhCN = require('vant/es/locale/lang/zh-CN')

Locale.use('zh-CN', zhCN)

;[
  Button,
  Icon,
  Empty,
  Tabbar,
  TabbarItem,
  Loading,
  Dialog,
  Form,
  Field,
  Notify,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Divider,
  Tag,
  Search,
  DropdownMenu,
  DropdownItem,
  Image,
  Cell,
  CellGroup,
  Uploader,
  ActionSheet,
  Skeleton,
  Picker,
  Switch,
  Locale,
  Col, 
  Row,
  Radio,
  RadioGroup
].forEach(component => {
  Vue.use(component)
})