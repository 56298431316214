






import { defineComponent, getCurrentInstance } from '@vue/composition-api'
import { checkIsLogin, getWeChatAppId, setLocalCorpDetail, WeChatType } from '@/api/login'
import { useBaseInfo } from '@/hook/useBaseInfo'
import { getAuthUrl } from '@/shared/wx-qy'
import { NotFoundType } from '@/constant/404'
import { isInQYWeChatBrowser } from '@/shared/utils'

async function checkFrontLogin() {
  const ctx = getCurrentInstance()
  const { riversAppId } = useBaseInfo()
  const isLogin = checkIsLogin()
  const isInQYWeChat = isInQYWeChatBrowser()

  if (isLogin) {
    // 已登录, 直接进入首页
    return ctx?.proxy.$router.push(`/${riversAppId}`)
  }

  try {
    /**
     * 获取企业微信的配置项
     * 如果配置了企业微信, 优先走企微的授权登录
     * 如果配置了企业微信, 但不在企业微信端, 降级走微信的手机登录
     */
    const { id: corpId, corpName: ylCropName, logoUrl: ylLogoUrl, agentId } = await getWeChatAppId(riversAppId, WeChatType.QY_WE_CHAT)
    if (corpId && isInQYWeChat) {
      const aU = location.href.includes('login-auth-v2') ? 'login-auth-v2' : 'login-auth'
      const redirectUrl = `${location.protocol}//${location.host}/${riversAppId}/${aU}`
      const authUrl = getAuthUrl(corpId, redirectUrl, agentId)
      // 设置本地登录的企业信息
      setLocalCorpDetail({
        appId: corpId,
        corpName: ylCropName.replace('易有料北京', '工深之星'),
        type: WeChatType.QY_WE_CHAT,
        corpLogo: ylLogoUrl,
      })
      return (window.location.href = authUrl)
      // return
    }

    /**
     * 获取微信的配置项
     * 如果未配置企微, 但配置了微信, 走手机号授权登录
     */
    const { id: appId, corpName, logoUrl } = await getWeChatAppId(riversAppId, WeChatType.WE_CHAT)
    if (appId) {
      ctx?.proxy.$router.push([`/${riversAppId}/login-phone?`, corpName ? `corpName=${corpName}` : ''].join(''))
      // 设置本地登录的企业信息
      setLocalCorpDetail({
        appId,
        corpName: corpName.replace('易有料北京', '工深之星'),
        type: WeChatType.WE_CHAT,
        corpLogo: logoUrl,
      })
      return
    }

    /**
     * 如果企微、微信都未配置. 404 配置错误
     */
    ctx?.proxy.$router.push(`/404/${NotFoundType.CONFIG_ERROR}`)
  } catch (err) {
    /**
     * 如果请求过程中报错. 权限错误 // TODO 提示信息可显示为 err.message
     */
    ctx?.proxy.$router.push(`/404/${NotFoundType.AUTH_ERROR}`)
  }
}

export default defineComponent({
  setup() {
    checkFrontLogin()

    return {}
  },
})
