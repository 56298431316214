import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Login from '@/views/Login/index.vue'
import AuthLoginV1 from '@/views/Login/AuthLoginV1.vue'
import AuthLoginV2 from '@/views/Login/AuthLoginV2.vue'
import AuthLoginWX from '@/views/Login/AuthLoginWX.vue'
import NotFound from '@/views/404.vue'
import TabBar from '@/views/TabBar.vue'
import { checkIsLogin, getLocalCorpDetail } from '@/api/login'
import { EventEmitter, setFavicon, Storage } from '@/shared/utils'

Vue.use(VueRouter)

export type T_ContentDetailRouterQuery = {
  sId: string // 对应 shardUserId. 分享者(转发者) id
  sEId: string // 对应 sourceEventId. 来源事件 id
}

const routes: Array<RouteConfig> = [
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: { title: '404' },
  },
  {
    path: '/404/:type',
    name: '404-desc',
    component: NotFound,
    meta: { title: '404' },
  },
  {
    path: '/404/desc/:desc',
    name: '404-desc-auto',
    component: NotFound,
    meta: { title: '404' },
  },

  /* 登录 */
  {
    path: '/:riversAppId/login',
    component: Login,
    meta: { title: '登录' },
  },
  {
    path: '/:riversAppId/login-phone',
    component: () => import(/* webpackChunkName: "phoneLogin" */ '@/views/Login/PhoneLogin.vue'),
    meta: { title: '验证码登录' },
  },
  {
    path: '/:riversAppId/login-auth',
    component: AuthLoginV1,
    meta: { title: '授权登录' },
  },
  {
    path: '/:riversAppId/login-auth-v2',
    component: AuthLoginV2,
    meta: { title: '授权登录' },
  },
  {
    path: '/:riversAppId/login-auth-wx',
    component: AuthLoginWX,
    meta: { title: '授权登录' },
  },

  {
    path: '/:riversAppId/content/:contentPoolId/:contentId/:contentType/:sellerId',
    name: 'contentDetail',
    component: () => import(/* webpackChunkName: "contentDetail" */ '../views/Content/ContentDetail/index.vue'),
    meta: { title: '内容' },
  },
  {
    path: '/:riversAppId/content/:contentId/:contentType/:sellerId',
    name: 'contentDetail',
    component: () => import(/* webpackChunkName: "contentDetail" */ '../views/Content/ContentDetail/index.vue'),
    meta: { title: '内容' },
  },

  {
    path: '/:riversAppId',
    component: TabBar,
    redirect: '/:riversAppId/content',
    children: [
      {
        path: 'content',
        component: () => import(/* webpackChunkName: "sellerContentList" */ '../views/Content/index.vue'),
        meta: { title: '内容列表' },
      },

      {
        path: 'recommend/:categoryId',
        component: () => import(/* webpackChunkName: "sellerRecommendList" */ '../views/Content/RecommandList.vue'),
        meta: { title: '推荐列表' },
      },
      {
        path: 'clues',
        component: () => import(/* webpackChunkName: "sellerClues" */ '../views/Clues/index.vue'),
        meta: { title: '线索' },
      },
      {
        path: 'customer',
        component: () => import(/* webpackChunkName: "sellerClustomer" */ '../views/Customer/index.vue'),
        meta: { title: '用户' },
      },
      {
        path: 'customer/:id',
        component: () => import(/* webpackChunkName: "sellerClustomer" */ '../views/Customer/CustomerDetail.vue'),
        meta: { title: '用户详情' },
      },
      {
        path: 'customer-image',
        component: () => import(/* webpackChunkName: "sellerClustomer" */ '../views/Customer/CustomerImage.vue'),
        meta: { title: '用户画像' },
      },
      // {
      //   path: 'customer-image',
      //   component: () => import(/* webpackChunkName: "base" */ '../views/Customer/CustomerDetail.vue'),
      //   meta: { title: '用户详情' }
      // },
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "sellerProfile" */ '../views/Profile/index.vue'),
        meta: { title: '我的' },
      },
      {
        path: 'profile-card',
        component: () => import(/* webpackChunkName: "sellerCard" */ '../views/Profile/Card.vue'),
        meta: { title: '我的名片' },
      },
      {
        path: 'profile-transmit',
        component: () => import(/* webpackChunkName: "sellerProfile" */ '../views/Profile/Transmit.vue'),
        meta: { title: '我的转发' },
      },
      {
        path: 'profile-statistics/:contentId/:contentPoolId',
        component: () => import(/* webpackChunkName: "sellerProfile" */ '../views/Profile/ForwardStatistics.vue'),
        meta: { title: '统计分析' },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  const riversAppId = to.params.riversAppId

  Storage.setKeyNS(`zy-${riversAppId || ''}`)

  /* 不需要校验, 直接返回的 path */
  const dontValidateNames = ['404', '404-desc']
  if (dontValidateNames.includes(to.name || '')) {
    return next()
  }

  /* url 没有 riversAppId, 跳转 404 */
  // const dontNeedRiversAppIdPaths = [ '/404' ]
  // if (!riversAppId && !dontNeedRiversAppIdPaths.includes(to.path)) {
  //   return next('/404')
  // }

  /* 登录状态校验 */
  const dontNeedLoginPaths = [
    '/:riversAppId/login',
    '/:riversAppId/login-phone',
    '/:riversAppId/login-auth-wx',
    '/:riversAppId/login-auth',
    '/:riversAppId/login-auth-v2',
    '/:riversAppId/content/:contentId/:contentType/:sellerId',
    '/:riversAppId/content/:contentPoolId/:contentId/:contentType/:sellerId',
  ]
  if (!checkIsLogin() && !isMatchedPath(dontNeedLoginPaths, to)) {
    return next(`/${riversAppId}/login`)
  }

  next()
})

router.afterEach(to => {
  EventEmitter.emit('RouterChanged', to)
  /**
   * 设置网站标题和 logo
   */
  const title = to.meta?.title
  const corpInfo = getLocalCorpDetail()
  const corpName = corpInfo?.corpName

  const documentTitle = ['', title].filter(v => !!v).join(' - ')

  // ylLog(`将设置标题和图标: corpName(${corpName})、title(${title})、documentTitle(${documentTitle})`)

  document.title = documentTitle

  corpInfo?.corpLogo && setFavicon(corpInfo?.corpLogo)
})

function isMatchedPath(targetPathList: string[], route: Route) {
  const matched = route.matched
  let hasMatched = false
  matched.forEach(route => {
    if (targetPathList.includes(route.path)) {
      hasMatched = true
    }
  })
  return hasMatched
}

export default router
