/* eslint-disable @typescript-eslint/no-explicit-any */
import { WeChatType, getWeChatAppId, getWeChatTicketId } from '@/api/login';
import { StorageKey } from '@/constant/keys';
import { ylError, ylLog } from './log';
import { DeviceType, getDeviceType, isInQYWeChatBrowser, Storage } from './utils'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { hex_sha1 } = require('./sha1.js')

export const WeChatSharedJSApi = [
  'onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ', 'onMenuShareWeibo', 'onMenuShareQZone',
  'onMenuShareWechat' // 此为企业微信自用
]

export const WeChatInvokeSharedJSApi = [
  'shareAppMessage', 'shareWechatMessage', 'shareToExternalMoments'
]

const jsApiList = [
  ...WeChatSharedJSApi,
  ...WeChatInvokeSharedJSApi,
  'openEnterpriseChat',
  'getContext'
]

// ylLog(`jsApiList: ${jsApiList.join('、')}`)

let wxInstance: any,
  cacheAppId: string,
  cacheTicket: string

function getLocalWeChatAppId(riversAppId: string) {
  const isInQYWeChat = isInQYWeChatBrowser()
  if (cacheAppId) {
    return Promise.resolve(cacheAppId)
  }

  const type = isInQYWeChat ? WeChatType.QY_WE_CHAT : WeChatType.WE_CHAT
  return getWeChatAppId(riversAppId, type).then(({ id }) => {
    cacheAppId = id
    return cacheAppId
  })
}

function getLocalTicket(riversAppId: string) {
  if (cacheTicket) {
    return Promise.resolve(cacheTicket)
  }

  const isInQYWeChat = isInQYWeChatBrowser()
  const type = isInQYWeChat ? WeChatType.QY_WE_CHAT : WeChatType.WE_CHAT
  return getWeChatTicketId(riversAppId, type).then(res => {
    cacheTicket = res.jsapiTicket
    return cacheTicket
  })
}

export function initWXInstance(riversAppId: string): Promise<any> {
  if (wxInstance) {
    return Promise.resolve(wxInstance)
  }

  const isInQYWeChat = isInQYWeChatBrowser() 

  return Promise.all([
    getLocalWeChatAppId(riversAppId),
    getLocalTicket(riversAppId)
  ]).then(([ appId, ticket ]) => {
    // debugger
    const signParams = getSignature(ticket)
    const configs = {
      debug: false,
      appId,
      ...signParams,
      jsApiList
    }

    ylLog(`appId: ${appId}`)

    if (isInQYWeChat) {
      Object.assign(configs, { beta: true })
    }

    const wx = (window as any).wx
    wx.config(configs)
    // initWXAgentConfig(appId, '1000006', signParams)

    return new Promise((resolve, reject) => {
      wx.error((err: any) => {
        reject()
        ylError('微信 SDK Error !!!', err)
      })
      wx.ready(() => {
        wxInstance = wx

        ylLog('微信 SDK Ready')

        // logEntryContext()

        wx.checkJsApi({
          jsApiList,
          success: function (res: any) {
            ylLog(`checkJsApi ${res ? JSON.stringify(res) : res}`)
          }
        })

        resolve(wxInstance)
      })

    })

  })
}


// function initWXAgentConfig(corpid: string, agentid: string, signatures: T_Signature) {
//   const agentConfigJSAPIList = [
//     'createChatWithMsg', 'shareToExternalMoments'
//   ]
//   const ticketId = '3HkoC3i1NQSea6tt/wMSKw=='
//   const signParams = getSignature(ticketId)
//   const wx = (window as any).wx
//   wx.agentConfig({
//     corpid,
//     agentid,
//     timestamp: signParams.timestamp, // 必填，生成签名的时间戳
//     nonceStr: signParams.nonceStr, // 必填，生成签名的随机串
//     signature: signParams.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
//     jsApiList: agentConfigJSAPIList, //必填，传入需要使用的接口名称
//     success: function (res: any) {
//       ylLog('wx.agentConfig success', res)
//     },
//     fail: function (res: any) {
//       if (res.errMsg.indexOf('function not exist') > -1) {
//         ylError('wx.agentConfig 版本过低')
//       } else {
//         ylError('wx.agentConfig error', res)
//       }
//     }
//   });
// }

export function reloadWXInstance(riversAppId: string) {
  wxInstance = null
  return initWXInstance(riversAppId)
}

export function getWXInstance() {
  return wxInstance
}

type T_Signature = {
  nonceStr: string,
  timestamp: string,
  signature: string,
}

function getSignature(ticket: string): T_Signature {
  const time = (+new Date() + '').substr(0, 10)
  const nonceStr = time + parseInt(Math.random() * 100100 + '')
  const isInQYWeChat = isInQYWeChatBrowser()

  let url = location.href.split('#')[0]
  if (getDeviceType() === DeviceType.IOS && !isInQYWeChat) {
    url = Storage.get(StorageKey.INITIAL_URL) as string
  }
    
  const signText = `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${time}&url=${url}`
  const signature = hex_sha1(signText)
  // ylLog(`jsapi_ticket: ${ticket}, noncestr: ${nonceStr}, timestamp: ${time}, url: ${url}`)
  // ylLog(`signature: ${signature}`)
  return {
    nonceStr,
    timestamp: time,
    signature,
  }
}

export type T_WXSharedParams = {
  title: string,
  desc?: string,
  link: string,
  imgUrl?: string
}

export function listenShared(params: T_WXSharedParams, fn: () => void) {
  if (!wxInstance) {
    ylLog('wxInstance 还未初始化成功')
    return false
  }

  WeChatSharedJSApi.forEach(apiName => {
    if (!wxInstance[apiName]) {
      return ylLog(`未找到 wx.${apiName}`)
    }

    // ylLog(`已设置监听 wx.${apiName}`)

    wxInstance[apiName]({
      ...params,
      success: () => {
        ylLog(`监听到分享事件 wx.${apiName}`)
        fn()
      }
    })
  })

}

export function listenSharedTimeline(params: T_WXSharedParams, fn: () => void) {
  wxInstance.onMenuShareTimeline && wxInstance.onMenuShareTimeline({
    ...params,
    success: () => {
      ylLog(`监听到分享到朋友圈事件`)
      fn()
    }
  })
}

function invoke(apiName: string, params: any) {
  if (!wxInstance) {
    ylLog('wxInstance 还未初始化成功')
    return Promise.reject(false)
  }

  return new Promise(resolve => {
    wxInstance.invoke && wxInstance.invoke(apiName, params, (res: any) => {
      ylLog(`调用 invoke ${apiName}`, res)
      if (res.err_msg === `${apiName}:ok`) {
        resolve(res)
      }
    })
  })
}

export function shareApp(params: T_WXSharedParams): Promise<any> {
  return invoke('shareAppMessage', params)
}

export function shareWeChat(params: T_WXSharedParams): Promise<any> {
  return invoke('shareWechatMessage', params)
}

type T_WXShareMomentParams = {
  title: string,
  link: string,
  desc: string, // 配文
  imgUrl: string
}

export function shareMoments(params: T_WXShareMomentParams) {
  return invoke('shareToExternalMoments', {
    text: {
      content: params.desc
    },
    attachments: [
      {
        msgtype: 'link',
        link: {
          title: params.title,
          imgUrl: params.imgUrl,
          url: params.link
        }
      }
    ]
  })
}

export function openEnterpriseChat(userIds: string[], externalUserIds: string[]) {
  wxInstance.openEnterpriseChat({
    // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
    userIds: userIds.join(';'),    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
    externalUserIds: externalUserIds.join(';'), // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
    groupName: '',  // 会话名称。单聊时该参数传入空字符串''即可。
    success: function (res: any) {
      const chatId = res.chatId //返回当前群聊ID，仅当使用agentConfig注入该接口权限时才返回chatId
      ylLog(`会话一打开: ${chatId}`)
    },
    fail: function (res: any) {
      if (res.errMsg.indexOf('function not exist') > -1) {
        ylLog('版本过低请升级')
      } else {
        ylLog('openEnterpriseChat 错误', res)
      }
    }
  });

}

export function createChatWithMsg(openIds: string[]) {
  wxInstance.invoke && wxInstance.invoke('createChatWithMsg', {
    selectedOpenUserIds: openIds,
    chatName: '与 Who 的对话',
    msg: {
      msgtype: 'link',
      link: {
        title: '测试标题',
        desc: '测试描述',
        url: 'https://www.baidu.com/',
        // imgUrl: 'imgurl1'
      }
    }
  }, function (res: any) {
    if (res.err_msg == 'createChatWithMsg:ok') {
      const chatId = res.chatId; // 新建的会话ID，当会话为单聊时不返回此字段
      ylLog('createChatWithMsg 创建成功', chatId)
    } else {
      ylError('createChatWithMsg error', res)
    }
  })
}

// function logEntryContext() {
//   ylLog('检查进入场景')
//   wxInstance.invoke && wxInstance.invoke('getContext', {
//   }, function (res: any) {
//     if (res.err_msg == 'getContext:ok') {
//       const entry = res.entry; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment
//       const shareTicket = res.shareTicket; //可用于调用getShareInfo接口
//       ylLog(`进入场景打印: entry: ${entry}、shareTicket: ${shareTicket}`)
//     } else {
//       ylLog('进入场景错误', res)
//     }
//   });
// }

export function getWXAuthUrl(corpId: string, redirectUri: string) {
  return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corpId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
}